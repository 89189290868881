import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as campaignActions from './../../../actions/campaignActions';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { PageTitle } from './../../../components/LayoutComponents/PageTitle';
import SignInModal from './../UnAuthTalentAlertContactCandidateProfile/SignInModal';
import SingleCandidate from './../TalentAlertContactDashboard/SingleCandidate';

import { SHORTLIST, FIRST_INTERVIEW, FURTHER_INTERVIEW, OFFER_MADE } from './../../../constants/candidateStatusIds';

const styles = {
  headerImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '55vh',
    // boxShadow: 'inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    textAlign: 'center'
  },
  videoWrapper: {
    marginTop: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  }
};

const useStyles = makeStyles((theme) => ({
  headerImg: styles.headerImg,
  headerImgMobile: {
    ...styles.headerImg,
    minHeight: '560px'
  },
  logo: {
    maxWidth: '270px',
    position: 'relative',
    margin: '0 auto',
    marginTop: '80px'
  },
  videoWrapper: {
    marginTop: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  video: {
    width: '700px'
  },
  videoMobile: {
    width: '90%'
  },
  introTextWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: '30px',
    padding: '50px',
    textAlign: 'center'
  },
  introHeader: {
    fontSize: '2rem',
    marginBottom: '20px'
  },
  introText: {
    margin: 'auto'
  },
  wrapper: {
    display: 'table',
    width: '70%',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  candidate: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px'
  },
  name: {
    fontSize: '1.5rem'
  },
  location: {
    fontSize: '1.3rem',
    marginLeft: '40px',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}));

const UnAuthTalentAlertContactDashboard = ({ loggedIn, campaignId, isPreview, cancelPreview }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['newTranslations']);
  const isMobile = useMediaQuery('(max-width:767px)');

  const contactCampaign = useSelector((state) => state.campaign);

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consultancyId, setConsultancyId] = useState(0);
  const [campaignTitle, setCampaignTitle] = useState('');

  const campaignCandidates = contactCampaign.campaignCandidates;

  if (contactCampaign?.campaignTitle && !campaignTitle) {
    setCampaignTitle(contactCampaign.campaignTitle);
  }

  useEffect(() => {
    const fetchConsultancyIdAndData = async () => {
      const consultancyId = await getConsultancyId(); // Assuming getConsultancyId returns a promise and resolves with the consultancyId
      
      if (loggedIn) {
        history.push(`/dashboard/talent-alerts/${campaignId}`);
        return;
      }
  
      setIsLoading(true);

      if (consultancyId !== 0 && consultancyId !== null) {
        try {
          const response = await talentAlertApi.getContactHiddenTalentAlertById(campaignId, consultancyId);
          dispatch({
            type: campaignActions.UPDATE_CAMPAIGN,
            campaign: response.data,
          });
        } catch (error) {
          enqueueSnackbar(t('newTranslations:failedToGetTalentAlert'), { variant: 'error' });
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false); // Ensure loading state is cleared even if consultancyId is 0 or null
      }
    };
  
    fetchConsultancyIdAndData();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, campaignId]);

  const getConsultancyId = async () => {
    try {
      const response = await talentAlertApi.getConsultancyId(window.location.hostname);
      setConsultancyId(response.data);
      return response.data; // Return consultancyId to use it if needed
    } catch (err) {
      console.log(err);
      return null; // Handle the error and return a fallback value if necessary
    }
  };

  const toggleSignInModal = () => {
    setSignInModalOpen((prevState) => !prevState);
  };

  const campaignCandidatesFilter = (candidate) => {
    switch (candidate.status) {
      case SHORTLIST:
      case FIRST_INTERVIEW:
      case FURTHER_INTERVIEW:
      case OFFER_MADE:
        return true;
      default:
        return false;
    }
  };

  const getHeaderLogoSrc = () => {
    if (contactCampaign?.campaignLogoImg) {
      return contactCampaign.campaignLogoImg;
    }

    return '';
  }

  return (
    <>
      <Helmet>
        <title>{t("newTranslations:talentAlert")}</title>
        <meta property='og:image' content={contactCampaign?.headerImg} />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='2292' />
      </Helmet>
      
      <div loading={isLoading} removeGutters>
        {
          isPreview && (
            <PageTitle
              pageTitle={ `${campaignTitle} - ${t("newTranslations:recruiterPreviewTalentAlertAsContactPreviewHeaderAdditionalLabel")}` }
              btnLabel={ t("newTranslations:recruiterPreviewTalentAlertAsContactBtnCancelPreview") }
              btnFunc={ cancelPreview }
              btnVariant="outlined" />
          )
        }

        <div className={classes.root} >
          <div
            item
            className={ isMobile ? classes.headerImgMobile : classes.headerImg }
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            style={{ backgroundImage: `url(${contactCampaign?.headerImg})` }} >
            <img src={ getHeaderLogoSrc() } alt="" className={classes.logo} />
            {
              contactCampaign.campaignVideo && (
                <div item className={classes.videoWrapper}>
                  <video className={ isMobile ? classes.videoMobile : classes.video } src={contactCampaign.campaignVideo} controls allowfullscreen />
                </div>
              )
            }
          </div>

          <Grid container className={classes.introTextWrapper}>
            <Grid item xs={12} md={6} className={classes.introText}>
              <Typography className={classes.introHeader}>
                { contactCampaign.introduction?.greetingCaption }
              </Typography>
              
              <Typography>
                { contactCampaign.introduction?.introductionText }
              </Typography>
            </Grid>
          </Grid>
          
          {
            campaignCandidates
              .filter(campaignCandidatesFilter)
              .map((candidate) => (
                  <SingleCandidate
                    candidateData={candidate}
                    campaignId={campaignId}
                    consultancyId={consultancyId}
                    key={candidate?.id}
                    loggedIn={loggedIn} />
                )
              )
          }

          <SignInModal
            open={signInModalOpen}
            handleClose={toggleSignInModal}
            campaignId={campaignId} />
        </div>
      </div>
    </>
  );

};

export default UnAuthTalentAlertContactDashboard;
