import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import parse from 'html-react-parser';

import { Grid, Typography, Avatar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LocationOn from '@material-ui/icons/LocationOn';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { SET_CANDIDATE_CAMPAIGN } from './../../../actions/candidateCampaignActions';

import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';
import { ContentWrapper } from './../../../components/LayoutComponents/ContentWrapper';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';

import Overview from './Overview';
import KeyFacts from './KeyFacts';
import CandidateThumbnail from './CandidateThumbnail';
import ContactRecruiterModal from './../../../components/ContactRecruiterModal';
import ContactCandidateKCQ from './../../ContactCandidateProfile/ContactCandidateKCQ';
import TalentAlertBackButton from './../../../components/LayoutComponents/TalentAlertBackButton';

const useStyles = makeStyles((theme) => ({
  headerImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '40vh',
    // boxShadow: 'inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center'
  },
  details: {
    margin: '-50px 20px 50px'
  },
  avatar: {
    width: 'auto',
    height: '200px',
    '&:hover': {
      cursor: 'pointer'
    },
    marginBottom: '10px'
  },
  paper: {
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: 'rgba(0, 0, 0, 0.30) 0px 5px 15px',
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '3px'
  },
  paperCandidateVideo: {
    position: 'relative',
    maxHeight: '680px'
  },
  button: {
    margin: '10px auto',
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    }
  },
  intro: {
    display: 'flex',
    flexDirection: 'row'
  },
  name: {
    fontSize: '1.8rem'
  },
  nameAndJob: {
    textAlign: 'left',
    marginLeft: '20px'
  },
  cta: {
    fontSize: '1.3rem',
    textAlign: 'center'
  },
  candidates: {
    display: 'flex',
    justifyContent: 'center'
  },
  wrapper: {
    display: 'table',
    width: 'auto',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapperCandidateVideo: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  videoBackground: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    opacity: '0.5',
    overflow: 'hidden',
    filter: 'blur(10px)'
  },
  videoBackgroundVideo: {
    width: '100%'
  },
  fakeDocument: {
    backgroundImage: 'url(https://res.cloudinary.com/dhiwlvpg7/image/upload/v1647007626/CV_opzjwd.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer'
    },
    marginBottom: '50px'
  },
  separator: {
    marginBottom: '50px',
    width: '50%',
    margin: 'auto'
  },
  fakeDocButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    },
    maxHeight: '40px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  truncate: {
    zIndex: '9',
    background: 'linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
    width: '100%',
    height: '200px',
    position: 'absolute',
    top: '0',
    left: '0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tWrapper: {
    width: '100%',
    position: 'relative',
    height: '200px'
  },
  box: {
    width: '100%',
    height: '200px',
    position: 'absolute',
    top: '0',
    left: '0',
    overflowY: 'hidden',
    textOverflow: 'ellipsis'
  },
  hr: {
    width: '60%',
    marginTop: '10px',
    marginBottom: '10px'
  },
  locationOn: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  location: {
    fontSize: '1.3rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center'
  },
  jobTitle: {
    fontSize: '1.2rem'
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  video: {
    height: '360px',
    width: '100%'
  },
  viewMore: {
    textAlign: 'center',
    cursor: 'pointer'
  }
}));

const TalentAlertContactCandidateProfile = ({ candidateId, campaignId, consultancyId, loggedIn, isPreview, cancelPreview }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation(['campaignCandidates', 'common', 'newTranslations', 'countries']);

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [shortExecutiveSummary, setShortExecutiveSummary] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [header, setHeader] = useState();
  const [candidateAvatar, setCandidateAvatar] = useState('');
  const [candidateProfileVideoSrc, setCandidateProfileVideoSrc] = useState('');
  const [campaignTitle, setCampaignTitle] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [areVideoEventsAttached, setAreVideoEventsAttached] = useState(false);

  const candidateCampaign = useSelector((state) => state.candidateCampaign.candidateProfile);

  const videoRef = useRef(null);
  const videoBackgroundRef = useRef(null);

  useEffect(() => {
    if (!loggedIn) {
      history.push(`/talent-alert/${campaignId}/${candidateId}`);
      return;
    }

    setIsLoading(true);

    if (!isPreview) {
      checkIfInvited();
    }

    getCandidateProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (candidateCampaign?.candidateProfilePicture) {
      setCandidateAvatar(candidateCampaign?.candidateProfilePicture);
    } else if (candidateCampaign?.profilePicture) {
      setCandidateAvatar(candidateCampaign.profilePicture);
    } else {
      setCandidateAvatar('');
    }

    setCandidateProfileVideoSrc(candidateCampaign?.candidateProfileVideo.url ? candidateCampaign.candidateProfileVideo.url : '');
    setCampaignTitle(candidateCampaign?.campaignTitle ? candidateCampaign.campaignTitle : '');
    setJobTitle(candidateCampaign?.jobTitle ? candidateCampaign.jobTitle : '');
  }, [candidateCampaign]);

  useEffect(() => {
    talentAlertApi.getCandidateProfile(consultancyId, candidateId, campaignId, isPreview)
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: { candidateProfile: response.data }
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId, campaignId, candidateId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!areVideoEventsAttached && videoRef && videoRef.current && videoBackgroundRef && videoBackgroundRef.current) {
    setAreVideoEventsAttached(true);

      videoRef.current.addEventListener('play', () => {
        videoBackgroundRef.current.play();
      });

      videoRef.current.addEventListener('pause', () => {
        videoBackgroundRef.current.pause();
      });
    }
  });

  const checkIfInvited = () => {
    talentAlertApi.checkIfInvited(campaignId)
      .then((response) => {
        if (!response.data) {
          history.push(`/talent-alert/${campaignId}/${candidateId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCandidateProfile = async () => {
    try {
      if (!isPreview) {
        const acceptedTCsResponse = await talentAlertApi.checkIfAcceptedTCs(campaignId);

        if (!acceptedTCsResponse.data.acceptedTCs) {
          history.push(`/terms-and-conditions/${campaignId}`);
          return;
        }

        setAuthenticated(acceptedTCsResponse.data);
      } else {
        setAuthenticated(true);
      }

      const candidateProfileResponse = await talentAlertApi.getCandidateProfile(consultancyId, candidateId, campaignId, isPreview);

      dispatch({
        type: SET_CANDIDATE_CAMPAIGN,
        candidateCampaign: { candidateProfile: candidateProfileResponse.data }
      });

      const talentAlertResponse = await talentAlertApi.getContactTalentAlertById(campaignId, consultancyId);

      setCandidates(talentAlertResponse.data.campaignCandidates);
      setHeader(talentAlertResponse.data.headerImg);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      history.push('/dashboard/talent-alerts');
    }
  };

  const toggleContactModal = () => {
    if (isPreview) {
      return;
    }

    setContactModalOpen((prevState) => !prevState);
  };

  const goToShortlist = () => {
    history.push({
      pathname: `/dashboard/talent-alerts/${campaignId}/`,
      state: {
        previousPath: history.location.pathname
      }
    });
  };

  const cancelPreviewHandler = () => {
    // Return candidate state for recruiters
    talentAlertApi.getCandidateProfile(consultancyId, candidateId, campaignId, isPreview)
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: { candidateProfile: response.data }
        });

        cancelPreview();
      });
  };

  const renderExecSummaryShort = () => {
    return (
      <>
        <div class={classes.tWrapper}>
          <div className={classes.box}>
            <Typography>
              { parse(candidateCampaign?.candidateExecutiveSummary || '') }
            </Typography>
          </div>

          <div className={classes.truncate} onClick={() => { setShortExecutiveSummary(false) }}></div>
        </div>

        <div className={classes.viewMore} onClick={() => { setShortExecutiveSummary(false) }}>
          {t("newTranslations:viewMore")}
        </div>
      </>
    );
  };

  const renderExecSummaryLong = () => {
    return (
      <>
        <Typography>
          { parse(candidateCampaign?.candidateExecutiveSummary || '') }
        </Typography>

        <div className={classes.viewMore} onClick={() => { setShortExecutiveSummary(true) }}>
          {t("newTranslations:viewLess")}
        </div>
      </>
    );
  };

  return (
    <ContentWrapper loading={isLoading} removeGutters>
      <TalentAlertBackButton />

      {
        isPreview && (
          <PageTitle
            pageTitle={ `${campaignTitle} - ${t("newTranslations:recruiterPreviewTalentAlertAsContactPreviewHeaderAdditionalLabel")}` }
            btnLabel={ t("newTranslations:recruiterPreviewTalentAlertAsContactBtnCancelPreview") }
            btnFunc={ cancelPreviewHandler }
            btnVariant="outlined" />
        )
      }

      <Grid
        container
        className={classes.root} >
        <Grid
          item
          className={classes.headerImg}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundImage: `url(${header})` }} >
        </Grid>

        <Grid container xs={12} justify="center">
          <Grid item xs={11} md={7} className={classes.details}>
            <Grid container item className={`${classes.paper} ${classes.intro}`}>
              <Grid item xs={12} md={3}>
                <Avatar
                  src={candidateAvatar}
                  variant="rounded"
                  className={classes.avatar} />
              </Grid>

              <Grid container item xs={12} md={9}>
                <Grid xs={7} className={classes.verticalCenter}>
                  <div className={classes.nameAndJob}>
                    <Typography className={classes.name}>
                      { candidateCampaign?.firstName } { candidateCampaign?.lastName }
                    </Typography>

                    <hr className={classes.hr} />

                    <Typography className={classes.jobTitle}>
                      { jobTitle }
                    </Typography>
                  </div>
                </Grid>

                <Grid xs={5} className={classes.verticalCenter}>
                  <div>
                    <LocationOn className={classes.locationOn} />

                    <Typography className={classes.location}>
                      {
                        candidateCampaign?.city ?
                        `${candidateCampaign.city}, ${t(`countries:${candidateCampaign?.country?.name}`)}` :
                        t(`countries:${candidateCampaign?.country?.name}`)
                      }
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={ `${classes.paper} ${classes.paperCandidateVideo}` }>
              <div className={ classes.videoBackground }>
                <video className={ classes.videoBackgroundVideo } src={ candidateProfileVideoSrc } ref={ videoBackgroundRef } playsInline muted loop />
              </div>

              <div className={ `${classes.wrapper} ${classes.wrapperCandidateVideo}` }>
                <video src={ candidateProfileVideoSrc } controls className={classes.video} ref={ videoRef } />
              </div>
            </Grid>

            {
              candidateCampaign?.candidateExecutiveSummary && (
                <>
                  <Grid item className={classes.paper}>
                    <BlockHeader header={t("common:executiveSummary")} />
      
                    { shortExecutiveSummary ? renderExecSummaryShort() : renderExecSummaryLong() }
                  </Grid>
                </>
              )
            }

            {
              candidateCampaign?.candidateKeyFacts && Object.keys(candidateCampaign?.candidateKeyFacts).length && (
                <>
                  <Grid item className={classes.paper}>
                    <BlockHeader header={t("newTranslations:keyFacts")} />
      
                    <KeyFacts
                      keyFacts={candidateCampaign?.candidateKeyFacts}
                      loggedIn={loggedIn} />
                  </Grid>
                </>
              )
            }

            {
              candidateCampaign?.candidateOverviews && candidateCampaign.candidateOverviews.length > 0 && (
                <>
                  <Grid item className={classes.paper}>
                    <Overview
                      overviews={candidateCampaign?.candidateOverviews}
                      loggedIn={loggedIn} />
                  </Grid>
                </>
              )
            }

            <ContactCandidateKCQ candidateCampaign={ candidateCampaign } />
          </Grid>

          <Grid item xs={11} md={2} className={classes.details}>
            <Grid item className={classes.paper}>
              <Typography className={classes.cta}>
                {t("newTranslations:interestedInThisCandidate")}
              </Typography>

              <Button className={classes.button} onClick={toggleContactModal}>
                {t("newTranslations:contactRecruiter")}
              </Button>
            </Grid>

            <Grid item className={classes.paper}>
              <BlockHeader header={t("campaignCandidates:candidateDocuments")} />

              {
                candidateCampaign?.campaignRequiredDocuments?.map((doc, index) => (
                  <a href={doc.url} target="_blank" rel="noopener noreferrer" key={index}>
                    <div className={classes.fakeDocument}>
                      <Button className={classes.fakeDocButton}>
                        {t("common:view")} {doc.name}
                      </Button>
                    </div>

                    <hr className={classes.separator} />
                  </a>
                ))
              }
            </Grid>

            {
              candidates.length > 1 && (
                <Grid item container className={`${classes.paper} ${classes.candidates}`}>
                  <Grid item>
                    <BlockHeader header={t("newTranslations:moreTalent")} />

                    {
                      candidates
                        .filter(candidate => candidate.id !== candidateId)
                        .map((candidate) => (
                          <CandidateThumbnail
                            key={candidate.id}
                            image={candidate.avatar}
                            campaignId={campaignId}
                            id={candidate.id}
                            jobTitle={candidate.jobTitle}
                            loggedIn={loggedIn} />
                        )
                      )
                    }
                  </Grid>

                  <Button className={classes.button} onClick={goToShortlist}>
                    {t("newTranslations:viewAllCandidates")}
                  </Button>
                </Grid>
              )
            }

          </Grid>
        </Grid>

        <ContactRecruiterModal
          open={contactModalOpen}
          handleClose={toggleContactModal}
          candidate={candidateCampaign}
          campaignId={campaignId}
          isTalentAlertCampaign={true}
          authenticated={authenticated} />
      </Grid >
    </ContentWrapper >
  );

};

export default TalentAlertContactCandidateProfile;
